import { Link, usePage } from "@inertiajs/inertia-react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import excerptHtml from "excerpt-html";
import React from "react";
import { Rating } from "react-simple-star-rating";
import PlainHorizontalLine from "./PlainHorizontalLine";
import UserActiveStatus from "./UserActiveStatus";
import PrimaryButtonLink from "./PrimaryButtonLink";
import { getUserRole } from "./Shared/GenericFunctions";

export default function SingleUser(props) {
    const { doctor } = props;
    return (
        <div className="col-span-1 mx-4" key={doctor.id}>
            <div className="flex flex-col gap-2 justify-between">
                <div className="w-full aspect-square relative ">
                    <Link href={route("psychic.details", doctor.id)}>
                        <img
                            src={
                                doctor.profile_photo_path
                                    ? "/" + doctor.profile_photo_path
                                    : "/assets/img/home4/team/team__thumb1.png"
                            }
                            alt={doctor.display_name}
                            className="rounded-md w-full h-full mx-auto hover:shadow-lg ease-in duration-500  object-cover"
                        />
                    </Link>
                    <UserActiveStatus doctor={doctor} />
                </div>
                <div className="text-center py-4">
                    <h3 className="mx-auto">
                        <Link href={route("psychic.details", doctor.id)}>
                            {doctor.display_name}
                        </Link>
                    </h3>
                    <PlainHorizontalLine />
                    <h6 className="">
                        <Link
                            href={route(
                                "psychic",
                                doctor.specialities[0]?.slug
                            )}
                        >
                            {doctor.specialities[0]?.name}
                        </Link>
                    </h6>
                </div>
                <div className="text-justify">
                    <p className="flex items-center justify-center ">
                        <Rating
                            initialValue={doctor.average_rating}
                            readonly
                            allowFraction={true}
                            size={25}
                        />
                    </p>

                    <p className="h-12">
                        {doctor.professional_information &&
                            doctor.professional_information.about && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: excerptHtml(
                                            stateToHTML(
                                                convertFromRaw(
                                                    JSON.parse(
                                                        doctor
                                                            .professional_information
                                                            .about
                                                    )
                                                )
                                            ),
                                            {
                                                pruneLength: 70, // Amount of characters that the excerpt should contain
                                                pruneString: "…", // Character that will be added to the pruned string
                                                pruneSeparator: " ", // Separator to be used to separate words
                                            }
                                        ),
                                    }}
                                />
                            )}
                    </p>
                </div>
                {getUserRole() == "member" && (
                    <div className="text-center">
                        <PrimaryButtonLink href={route("chatify", doctor.id)}>
                            Chat Now
                        </PrimaryButtonLink>
                    </div>
                )}
                {usePage().props.auth.user == null &&
                 <div className="text-center">
                        <PrimaryButtonLink href={route("login")}>
                            Chat Now
                        </PrimaryButtonLink>
                    </div>
                    }
            </div>
        </div>
    );
}
