import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import { Head, Link } from "@inertiajs/inertia-react";
import { useEffect, useState } from "react";
import SecondaryButtonLink from "@/Components/SecondaryButtonLink";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AsyncSelect from "react-select/async";
import axios from "axios";
import excerptHtml from "excerpt-html";
import FAQGrid from "@/Components/FAQ/FAQGrid";
import PrimaryButtonLink from "@/Components/PrimaryButtonLink";
import SingleUser from "@/Components/SingleUser";
import DottedHorizontalLine from "@/Components/DottedHorizontalLine";
import OneTimePopup from "@/Components/Popups/OneTimePopup";
import { getUserRole } from "@/Components/Shared/GenericFunctions";


export default function Home(props) {
    var psychicsList = [];
    const APP_URL = import.meta.env.VITE_APP_URL;
    const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
    const LOGGED_IN_ROLE = getUserRole();
    var faqsList = props.faqs;
    const [psychicSearchedList, setPsychicSearchedList] = useState([]);

    var settings1 = {
        infinite: true,
        speed: 500,
        slidesToShow:
            props.doctorsList.length < 4 ? props.doctorsList.length : 4,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };
    useEffect(() => {
        console.log(props.doctorsList);
    }, []);
    const formatOptionLabel = ({
        id,
        profile_photo_path,
        display_name,
        name,
        speciality_name,
    }) => (
        <Link href={route("psychic.details", id)}>
            <div className="flex justify-left space-x-2 align-items-center text-black">
                <div className="">
                    <img
                        className="w-12 h-12 rounded-full"
                        src={window.location.origin + "/" + profile_photo_path}
                    />
                </div>
                <div className="">
                    <div className="font-bold">{display_name}</div>
                </div>
            </div>
        </Link>
    );
    const handleSelectKeyDown = (event) => {
        if (event.target.value.length > 2 && event.key === "Enter") {
            var foundSearchObj = psychicSearchedList.filter((e) =>
                e.display_name
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            );
            if (foundSearchObj == "") {
                window.location.href = route("psychic", {
                    search: event.target.value,
                });
            }
        }
    };
    const handleSelectOnChange = (event) => {
        window.location.href = route("psychic.details", { id: event.id });
    };
    const loadOptions = (inputValue) => {
        if (inputValue.length < 3) return;
        return axios
            .get(route("psychic", { search: inputValue }))
            .then((res) => {
                setPsychicSearchedList(res.data);
                return res.data;
            })
            .catch((err) => {
                console.log("erro psychic", err);
            });
    };
        // Function to clear the popup cookie when the component is unmounted (browser closed)
        useEffect(() => {
            const handleBeforeUnload = (event) => {
                if (isOtpPopupOpen) {
                    event.preventDefault(); // Prevents the default action of the event
                    event.returnValue = ''; // Required for Chrome compatibility
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }, []);

        // Function to set a cookie
        const setCookie = (name, value, days) => {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        };

        // Function to get a cookie value by name
        const getCookie = (name) => {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        };

        // Function to check if the popup should be shown
        const shouldShowPopup = () => {
            return !getCookie('popupShown');
        };

        // Function to display the popup
        const showPopup = () => {
            setIsOtpPopupOpen(true);
            setCookie('popupShown', 'true', 0); // Cookie expires in 1 day
            // Your code to display the popup here
            // For example:
            // document.getElementById('popup').style.display = 'block';
        };

        // Function to close the popup
        const closeOtp = () => {
            setIsOtpPopupOpen(false);
            setCookie('popupShown', 'true', 0); // Cookie expires in 1 day
        };

        // Check if the popup should be shown on component mount
        useEffect(() => {
            if (shouldShowPopup()) {
                showPopup();
                // Set cookie to indicate that the popup has been shown
            }
        }, []);

    return (
        <AuthenticatedLayout
            page={"home"}
            auth={props.auth}
            errors={props.errors}
            header={"Home"}
        >
            <Head title="Home" />
            <OneTimePopup
                closePopup={() => {
                    closeOtp();
                }}
                isOpen={isOtpPopupOpen}
            />
            <section className="fact-area -mt-10 pos-rel pt-25 pb-25">
                <svg
                    className="homepageShapeDesign shapeBottom"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                >
                    {" "}
                    <path
                        transform=""
                        className="fill-white"
                        d="M0,100.136212 L0,41.5975353 C18.8339184,71.5220681 35.500585,72.0482732 50,43.1761506 C71.7491224,-0.132033358 87.1919401,-17.6187176 100,25.6894663 C100,64.7152509 100,89.7060071 100,100.661735 L0,100.136212 Z"
                    ></path>{" "}
                </svg>
            </section>
            <section className="services-area  pos-rel pt-10 pb-10">

                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-55">
                                <div className="section-text pos-rel">
                                    <h2 className="font-bold">Featured Advisors</h2>
                                    <DottedHorizontalLine/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-1 sm:grid-cols-4 gap-y-12">
                        {props.featuredDoctorsList &&
                            props.featuredDoctorsList.map((doctor, index) => (
                                <SingleUser key={"all_advisors_"+doctor.id} doctor={doctor} />
                            ))}
                    </div>
                    <div className="mt-4 text-center">
                        <PrimaryButtonLink href={route("psychic")}>
                            See More
                        </PrimaryButtonLink>
                    </div>
                </div>
            </section>
            <section className="bg-gradient-to-r from-secondary via-[#3F618E] to-secondary py-10">
                <div className="container text-center">
                    <h3 className="pb-4 text-white font-bold">
                        When starting a reading, get $15 FREE CREDIT
                    </h3>
                    <p className="pb-4 text-white text-lg">
                        We realize that choosing the right advisor is important
                        for your satisfaction. Our Best Match Guarantee gives
                        you $15 free credits with each new advisor, allowing you
                        to pick the best match for you before you start a paid
                    reading. Our guarantee applies to your first chat
                        reading with any new advisor and to your first phone
                        reading only.
                    </p>
                </div>
            </section>
            <section className="services-area  pos-rel pt-10 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-55">
                                <div className="section-text pos-rel">

                                    <h2 className="font-bold">All Advisors</h2>
                                    <DottedHorizontalLine/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-1 sm:grid-cols-4 gap-y-12">
                        {props.doctorsList &&
                            props.doctorsList.map((doctor, index) => (
                                <SingleUser key={"all_advisors_"+doctor.id} doctor={doctor} />
                            ))}
                    </div>
                    <div className="mt-4 text-center">
                        <PrimaryButtonLink href={route("psychic")}>
                            See More
                        </PrimaryButtonLink>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 about-area  py-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="about-title text-center mb-20">
                                <h5>How It Works?</h5>
                                <h2 className="font-bold">
                                    Only 3 Simple Steps
                                </h2>
                                <DottedHorizontalLine/>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-4">
                            <div className="shadow-lg rounded h5services-wrapper lg:mr-2  py-10  border-2 border-secondary">
                                <div className="h5services-content d-block text-center  ">
                                    <i className=" mx-auto">
                                        <img
                                            src="/assets/img/home/steps/step-1.png"
                                            className="mx-auto w-32 d-block"
                                            alt="icon"
                                        />
                                    </i>
                                    <h4 className="py-4 h5services-title">
                                        Creating an Account
                                    </h4>
                                    <PrimaryButtonLink
                                        className="text-uppercase f-500"
                                        href={route("register")}
                                    >
                                            Create an Account
                                    </PrimaryButtonLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-0 xs:!my-2">
                            <div className=" shadow-lg  rounded h5services-wrapper py-10 lg:mx-2  border-2 border-secondary">
                                <div className="h5services-content text-center">
                                    <i className="mx-auto">
                                        <img
                                            src="/assets/img/home/steps/step-2.png"
                                            className="mx-auto d-block  w-32"
                                            alt="icon"
                                        />
                                    </i>
                                    <h4 className="py-4 h5services-title">
                                        Finding Psychic
                                    </h4>
                                    <AsyncSelect
                                        placeholder="Type 3 letters to Search"
                                        cacheOptions
                                        defaultOptions
                                        getOptionLabel={(e) => e.display_name}
                                        getOptionValue={(e) => e.id}
                                        loadOptions={loadOptions}
                                        formatOptionLabel={formatOptionLabel}
                                        onKeyDown={handleSelectKeyDown}
                                        onChange={handleSelectOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="shadow-lg rounded h5services-wrapper py-10 lg:ml-2 border-2 border-secondary">
                                <div className="h5services-content text-center">
                                    <i className="mx-auto">
                                        <img
                                            src="/assets/img/home/steps/step-3.png"
                                            className="mx-auto d-block  w-32"
                                            alt="icon"
                                        />
                                    </i>
                                    <h4 className="py-4 h5services-title">
                                        Conect with Psychic
                                    </h4>
                                    <PrimaryButtonLink
                                        className="text-uppercase f-500"
                                        href={route("psychic")}
                                    >
                                        <span className="">View All</span>
                                    </PrimaryButtonLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-area mt-10 pt-30 pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">
                                <div className="section-text pos-rel">
                                    <h2 className="font-bold">
                                        Most Popular Psychics
                                    </h2>
                                    <DottedHorizontalLine/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <Slider {...settings1}>
                            {props.doctorsList &&
                                props.doctorsList.map((doctor, index) => (
                                    <SingleUser key={"most_popular_"+doctor.id} doctor={doctor} />

                                ))}
                        </Slider>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100  pb-20 pt-20">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">
                                <div className="section-text pos-rel">
                                    <h2 className="font-bold">FAQ's</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-8">
                        {faqsList.map((obj, ind) => {
                            return <FAQGrid key={"faq_"+obj.id} data={obj} />;
                        })}
                    </div>
                </div>
            </section>
        </AuthenticatedLayout>
    );
}
